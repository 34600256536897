import { BwTableTooltip, BwTableTooltipContent, BwTableTooltipTrigger } from '@/components/ui/tooltip'
import InfoIcon from '@/icons/bwi/info-circle.svg'
import { useCallback } from 'react'
import { cx } from '@/utils/strings'

type LabelFloatingProps = {
  children: React.ReactNode
  label: string
  required?: boolean
  uid: string
  hasFocus?: boolean
  hasInput?: boolean
  name: string
  tooltip?: string
  fieldsetCss?: TwStyle
  labelCss?: TwStyle
}

export default function LabelFloating({
  children,
  label,
  required,
  uid,
  hasFocus,
  hasInput,
  name,
  tooltip,
  fieldsetCss,
  labelCss,
}: LabelFloatingProps): JSX.Element {
  const Tooltip = useCallback(
    ({ text }) => (
      <BwTableTooltip>
        <BwTableTooltipTrigger asChild>
          <button type="button">
            <InfoIcon className="w-[18px] h-[18px] ml-2 text-gray-600" />
          </button>
        </BwTableTooltipTrigger>
        <BwTableTooltipContent className="w-auto!">{text}</BwTableTooltipContent>
      </BwTableTooltip>
    ),
    []
  )
  return (
    <fieldset
      role="group"
      className={cx(
        'relative w-full mb-2 border rounded border-gray-300 hover:border-indigoBlue transition-[border-color, border-width box-shadow',
        hasFocus && '!border-primaryBlue shadow-md',
        fieldsetCss
      )}
    >
      <div className="flex">
        <label
          htmlFor={uid}
          data-testid={`${name}-label`}
          className={cx(
            'absolute left-[14px] top-[11px] lg:top-[14px] rounded pb-1 text-gray-600 max-w-fit right-7 truncate transition-[transform font-size] bg-transparent',
            (hasFocus || hasInput) && 'text-xs !-top-[9px] lg:!-top-[10px]',
            hasFocus && 'text-primaryBlue',
            labelCss
          )}
        >
          <span className="flex">
            <span className="pointer-events-none">
              {label}
              {required && <span className={cx('ml-px', hasFocus && 'text-red-700')}>*</span>}
            </span>
            {tooltip && <Tooltip text={tooltip} />}
          </span>
        </label>
      </div>
      {/* just needed for spacing the notch in the border */}
      <legend
        className={cx(
          'text-xs max-w-0 invisible h-0 ml-2 transition-[max-width] duration-500',
          (hasFocus || hasInput) && 'max-w-full px-1'
        )}
      >
        {label}
        {required && <span className={cx('ml-px', hasFocus && 'text-red-700')}>*</span>}
        {tooltip && <Tooltip text={tooltip} />}
      </legend>
      {children}
    </fieldset>
  )
}
